
.App {
  text-align: center;
  font-family: 'fahkwang';
 height: 100vh;
}

body {
  /* background-image: url("../src/assets/imgs/middle-banner.png"); */
  /* background-repeat: no-repeat; */
  background-color: #151515;
}
