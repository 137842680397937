.title {
  font-family: "Fahkwang";
  font-size: 48px;
  color: white;
  padding-left: 100px;
  padding-top: 100px;
}
.noun {
  color: white;
  display: flex;
  font-size: 20px;
  justify-content: start;
  padding-left: 100px;
  margin-top: -19px;
}
.sub-title-lab {
  color: white;
  font-family: "Fahkwang";
  font-size: 36px;
  line-height: 46.8px;
  width: 526px;
  text-align: start;
  padding-left: 100px;
}

.img {
  display: flex;
  justify-content: start;
  padding-left: 100px;
  margin-top: 100px;
}

.small-img {
  margin-top: 110px;
  margin-left: -135px;
  border-radius: 15px;
}
.small-img:hover {
  margin-left: -300px;
}

.small-data {
  font-family: "Fahkwang";
  font-size: 20px;
  line-height: 26px;
  text-align: start;
  margin-left: 30px;
  margin-top: 30px;
  padding: 10px;
  width: 400px;
}
