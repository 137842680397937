.middle-banner {
}
.text-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 200px;
}
.middle-banner-title {
  padding-top: 123px;
  color: white;
  font-weight: 400;
  font-size: 48px;
  line-height: 62.4px;
  margin: 0px;
}
.middle-banner-subtitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 31.2px;
  color: #797979;
  font-family: Fahkwang;
  text-align: left;
  margin: 0px;
}

.img-with-text {
  background-image: url("../../../assets/imgs/img.png");
  width: 350px;
  height: 525.72px;
  border-radius: 15px;
}
.text-inside-img {
  color: white;
  position: absolute;
  top: 312px;
  text-align: center;
  width: 100%;
  font-family: Fahkwang;
  font-weight: 500;
  font-size: 48px;
  text-shadow: 4px 12px 20px #000000;
  text-decoration: line-through;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  padding-top: 42px;
  gap: 0px 0px;
}

.grid-item {
  width: 406.49px;
  height: 525.72px;
  border-radius: 15;
  top: 0;
  left: 0;
}

.thumbnail-image {
  position: absolute;
  width: 406.49px;
  height: 525.72px;
  border-radius: 15px;
  border: 2px;
  top: 0;
  left: 0;
  object-fit: cover;
}
.video-play {
  border-radius: 15px;
  width: 406.49px;
  height: 525.72px;
}
.proclaimed {
  font-family: Fahkwang;
  font-size: 23px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #656565;
}
.trade-marks {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
}

.trade-marks-logo:hover {
  cursor: pointer;
  height: 7% !important;
  width: 7% !important; 
  }
