.marquee{
  background-color:black;
  display: flex;
  color: white;
}
.marquee-box{
 display: flex;
 margin: auto;
}
.marquee-text{
  padding-inline: 30px;
  font-weight: 400;
  font-size: 24px;
  line-height: 31.2px;
}

