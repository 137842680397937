.main-mid-banner-box {
  height: 785px;
}

.video-container {
  overflow: hidden;
  margin-top: -70px;
}

.video-container video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  z-index: 5;
  left: 0px;
}

.video-container .caption {
  z-index: 10;
  position: relative;
  text-align: center;
  padding: 10px;
}
