.middle-text-box {
  padding-top:120px;
}
.title {
  font-size: 48px;
  font-weight: 700;
  line-height: 62.4px;
  color: white;
  font-family: Fahkwang;
  margin-block: 0px;
}
.sub-title {
  font-size: 16px;
  color: #A6A6A6;
  line-height: 20.8px;
  font-family: Fahkwang;
  margin-block: 0px;
}
