.main-header-box {
  display: flex;
  justify-content: space-between;
  padding-inline: 16px;
  padding-block: 14px;
  align-items: center;
  z-index: 10;
  position: relative;
}
.right-side {
  display: flex;
  gap: 8px;
}
.header-item {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  font-family: Fahkwang !important;
}
